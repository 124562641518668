body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.orange {
    color: #d80000;
}

a:link, a:visited {
    color: #444;
}

.bg-light {
  background: #ffffff !important;
}

.logo {
  max-height: 50px !important;
}

.navbar-nav {
    text-transform: uppercase !important;
}

.navbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

hr {
    margin-top: 0px !important;
}

footer {
    text-transform: uppercase;
    font-size: .75em;
}