.title {
    font-family: 'Oswald', sans-serif;
    font-size: 1.25rem !important;
}

.url {
    color: #444 !important;
    text-decoration: none;
}

.url:hover {
    color: #a50000 !important;
    text-decoration: none;
}

.readAll {
    text-transform: uppercase;
    font-size: .6rem;
    float: right;
    color: #d80000 !important;
}

.body {
    padding: 1rem !important;
}

.footer {
    padding: .4rem 1.25rem !important;
}